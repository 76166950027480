import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtom } from 'jotai';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import DefaultProfile from '../../assets/default-profile.svg';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { userState } from '../../state/userState';
import { CompanyAPIResult } from '../../types/api/companyTypes';
import { HeaderUserMenuCMSResult } from '../../types/cms/headerTypes';
import { QueryParams } from '../../utils/constants';
import { getImageFromApiUrl, loadFromApi } from '../../utils/http';
import { getQueryParamsFromUrl } from '../../utils/links';
import { PopoverMenu, PopoverMenuScroll, PopoverMenuSeparator } from 'legacy-ui';
import { Text } from 'legacy-ui';
import { useWhiteLabel } from '../../providers/WhiteLabelProvider';
import { getMenuItems } from './functions';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';
import { isBelowBreakpoint } from '../../styles/theme';
import { useTranslation } from 'next-i18next';

interface UserMenuProps {
  data?: HeaderUserMenuCMSResult;
}

const ProfilePicture = styled(Image)`
  width: 20px;
  height: 20px;
  border-radius: 100%;
`;

const ProfileWrapper = styled.div`
  > button {
    > span {
      vertical-align: middle;
      max-height: 32px !important;
      max-width: 32px !important;
    }
    > svg {
      margin-left: 8px;
    }
    &:hover {
      img {
        opacity: 0.9;
      }
    }
  }
`;

const ProfileName = styled.span`
  margin-left: 8px;
`;

const UserMenu = ({ data }: UserMenuProps) => {
  const [showUserPopover, setShowUserPopover] = useState(false);
  const [companies, setCompanies] = useState<CompanyAPIResult[]>([]);
  const [{ status, user }, setUser] = useAtom(userState);
  const router = useRouter();
  const { locale, query } = router;
  const ref = useRef<HTMLDivElement>(null);
  const {
    isWhiteLabel,
    restrictedPages,
    urls: { spa: spaBaseUrl },
  } = useWhiteLabel();
  const { t } = useTranslation('general');

  const menuItems = useMemo(
    () => getMenuItems(user, companies, isWhiteLabel, data, locale, spaBaseUrl, t, restrictedPages),
    [user, companies, isWhiteLabel, data, locale, spaBaseUrl, t, restrictedPages],
  );
  const windowSize = useGetWindowSize();
  const isMobile = isBelowBreakpoint(windowSize?.width || 0, 'md');

  useOnClickOutside(ref, setShowUserPopover);

  useEffect(() => {
    if (!user && companies.length) {
      setCompanies([]);
    }
    if (!companies.length && status === 'loaded' && user?.authorities.some((authority) => authority.companyId && authority.companyId !== '-1')) {
      (async () => {
        setCompanies(await loadFromApi('user/companies'));
      })();
    }
  }, [status, user, companies]);

  async function logout() {
    await loadFromApi('auth/logout');
    setShowUserPopover(false);
    setUser({
      status: 'loaded',
      user: undefined,
    });
    const urlContainsQueryParams = document.location.href.includes('?');
    document.location.href = urlContainsQueryParams
      ? `${document.location.href}&${QueryParams.AuthLogout}`
      : `${document.location.href}?${QueryParams.AuthLogout}`;
  }

  if (status !== 'loaded' || !user) {
    return null;
  }

  const getProfileName = () => {
    if (isMobile || !isWhiteLabel) {
      return null;
    }
    return <ProfileName>{`${user.firstName} ${user.lastName}`}</ProfileName>;
  };

  return (
    <ProfileWrapper ref={ref}>
      <button onClick={() => setShowUserPopover((prev) => !prev)}>
        <ProfilePicture src={user.image ? getImageFromApiUrl(user.image.name, 128) : DefaultProfile} width="180" height="180" />
        {getProfileName()}
        <FontAwesomeIcon icon={faAngleDown} />
      </button>
      {showUserPopover && (
        <PopoverMenu>
          {menuItems.map((sectionItems, index) => (
            <Fragment key={index}>
              {'isDivider' in sectionItems ? (
                <PopoverMenuSeparator />
              ) : (
                <PopoverMenuScroll key={index} isScrollable={sectionItems.isScrollable}>
                  {sectionItems.items?.map((item, index) => (
                    <li key={index}>
                      <Link href={item.link} passHref>
                        <a>
                          {item.logo && (
                            <>
                              <ProfilePicture src={item.logo} width="24" height="24" />{' '}
                            </>
                          )}
                          <Text>{item.label}</Text>
                        </a>
                      </Link>
                    </li>
                  ))}
                </PopoverMenuScroll>
              )}
            </Fragment>
          ))}
          <li>
            <a role="button" onClick={logout}>
              {data?.LogoutLabel}
            </a>
          </li>
        </PopoverMenu>
      )}
    </ProfileWrapper>
  );
};

export default UserMenu;
