import { CompanyAPIResult } from '../../types/api/companyTypes';
import { UserAPIResult } from '../../types/api/userTypes';
import { HeaderUserMenuCMSResult } from '../../types/cms/headerTypes';
import { LanguageKey } from '../../types/common';
import { ADMIN_ROLE_NAME } from '../../utils/constants';
import { getImageFromApiUrl } from '../../utils/http';
import { getRoute } from '../../utils/languages';
import { getAccountLink } from '../../utils/links';
import { getCompanyDashboardLink } from '../../utils/spa-integration';
import DefaultCompany from '../../assets/default-company.svg';
import { TFunction } from 'i18next';

type Divider = {
  isDivider: true;
};

type MenuItem = {
  label: string | undefined;
  link: string;
  logo?: string;
};
type MenuSection =
  | {
      isScrollable: boolean;
      items: MenuItem[];
    }
  | Divider;

const getLabelAndLink = (label: string | undefined, link: string) => ({
  label,
  link,
});

const divider = (): Divider => ({
  isDivider: true,
});

const section = (items: MenuItem[], isScrollable = false): MenuSection => ({
  isScrollable,
  items,
});

function getCompanies(companies: CompanyAPIResult[], locale: string | undefined, spaBaseUrl: string) {
  if (!companies.length) {
    return [];
  }
  return companies.map((company) => ({
    ...getLabelAndLink(company.name, getCompanyDashboardLink(company.id, locale as LanguageKey, spaBaseUrl)),
    logo: company.logo ? getImageFromApiUrl(company.logo.name, 128) : DefaultCompany,
  }));
}

export function getMenuItems(
  user: UserAPIResult | undefined,
  companies: CompanyAPIResult[],
  isWhiteLabel: boolean,
  data: HeaderUserMenuCMSResult | undefined,
  locale: string | undefined,
  spaBaseUrl: string,
  t: TFunction<'waitingList', undefined>,
  restrictedPages: string[] = [],
): MenuSection[] {
  if (!user) {
    return [];
  }

  const menuItems: Record<string, MenuItem | undefined> = {
    applications: !restrictedPages.includes('applications')
      ? getLabelAndLink(data?.ApplicationsLabel, getRoute('/applications', locale as LanguageKey))
      : undefined,
    savedSearches: !restrictedPages.includes('saved-searches')
      ? getLabelAndLink(data?.SavedSearchesLabel, getRoute('/saved-searches', locale as LanguageKey))
      : undefined,
    interestLists: !restrictedPages.includes('interest-lists')
      ? getLabelAndLink(data?.InterestListsLabel, getRoute('/interest-lists', locale as LanguageKey))
      : undefined,
    waitingLists: !restrictedPages.includes('waiting-lists')
      ? getLabelAndLink(t('menuItem.waitingLists'), getRoute('/waiting-lists', locale as LanguageKey))
      : undefined,
    account: !restrictedPages.includes('account') ? getLabelAndLink(data?.AccountLabel, getAccountLink(locale as LanguageKey)) : undefined,
  };

  const section1 = [menuItems.applications, menuItems.savedSearches, menuItems.interestLists, menuItems.waitingLists].filter(Boolean) as MenuItem[];

  const section2 = [
    !isWhiteLabel &&
      user.authorities.some((authority) => authority.roleId === ADMIN_ROLE_NAME) &&
      getLabelAndLink(data?.AdminLabel, `${spaBaseUrl}/${locale}/a/admin/applications`),
    menuItems.account,
  ].filter(Boolean) as MenuItem[];

  const items: (MenuSection | false)[] = [
    !isWhiteLabel && section(getCompanies(companies, locale, spaBaseUrl), true),
    !isWhiteLabel && companies.length > 0 && divider(),
    section(section1),
    !isWhiteLabel && divider(),
    section(section2),
  ];

  return items.filter(Boolean) as MenuSection[];
}
