import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const appear = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.9) translateY(-10%);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
`;

export const ModalOverlay = styled.div`
  height: 100vh;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.4) !important;
  z-index: 99;
  animation: ${fadeIn} 0.4s ease-out;
  padding: 64px 0;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    padding: 96px 0;
  }
`;

export const ModalContent = styled.div<{ maxWidth?: string }>`
  max-width: ${(props) => props.maxWidth || '496px'};
  width: 100%;
  background-color: white;
  box-shadow: 0 16px 64px 0 rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  margin: 0 auto;
  padding: 64px 24px 32px 24px;
  animation: ${appear} 0.4s ease-out;
  position: relative;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    padding: 64px 48px 32px 48px;
  }
`;

export const ModalClose = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.color.gray20};
  }
  &:active {
    background-color: ${(props) => props.theme.color.gray30};
  }
  svg {
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
